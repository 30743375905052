<template>
  <div class="container container-section">
    <img class="logo-seccion" :src="seccion.logo" alt="" />
    <h1 class="titulo-seccion">{{ seccion.name }}</h1>
    <p class="descripcion">{{ seccion.description }}</p>
    <div
      class="narrativa"
      v-if="seccion.narrativa"
      v-html="seccion.narrativa"
    ></div>

    <template v-if="seccion.typePublication == 1">
      <PubListAuditorias :docto="this.$route.params.id" />
    </template>
    <template v-if="seccion.typePublication == 2">
      <PubListDocuments :docto="this.$route.params.id" />
    </template>
    <template v-if="seccion.typePublication == 3">
      <PubListInformes :docto="this.$route.params.id" />
    </template>
    <template v-if="seccion.typePublication == 4">
      <PubListLinks :docto="this.$route.params.id" />
    </template>
    <template v-if="seccion.typePublication == 5">
      <PubListText :docto="this.$route.params.id" />
    </template>
  </div>
</template>

<script>
import firebase from "../firebase";
import PubListAuditorias from "../components/PubListAuditorias";
import PubListDocuments from "../components/PubListDocuments";
import PubListInformes from "../components/PubListInformes";
import PubListLinks from "../components/PubListLinks";
import PubListText from "../components/PubListText";

export default {
  name: "Seccion",
  components: {
    PubListAuditorias,
    PubListDocuments,
    PubListInformes,
    PubListLinks,
    PubListText,
  },
  data() {
    return {
      key: "",
      seccion: {},
    };
  },
  created() {
    const ref = firebase
      .firestore()
      .collection("secciones")
      .doc(this.$route.params.id);
    ref.get().then((doc) => {
      if (doc.exists) {
        this.key = doc.id;
        this.seccion = doc.data();
      } else {
        alert("No existe la sección!");
      }
    });
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.logo-seccion {
  width: 100px;
  display: block;
  margin: auto;
}

.descripcion {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.75rem;
}
.narrativa {
  margin-bottom: 3rem;

  p {
    a {
      text-decoration: none;
      background: #e7e7e7;
      padding: 0.25rem 1rem;
      //border: 1px solid black;
      border-radius: 1rem;
      color: $bg-01;
      transition: all 0.2s linear;

      &:hover {
        background: $bg-01;
        color: white
      }
    }
  }
}
</style>
