<template>
  <div class="list-group personal-list-group">
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado Textos
    </p>
    <div v-else>
      <div
        class="container-texto"
        v-for="i in items"
        :key="i.key"
        v-html="i.texto"
      ></div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["docto"],
  name: "PubListText",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: [],
    };
  },
  created() {
    this.ref.orderBy("orden").where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
  .container-texto {
    margin: 5rem 2rem;

    ::v-deep h1 {
      font-family: $font-01;
      font-size: 1.5rem;
      margin: 1.5rem 0rem;
    }
    ::v-deep h2 {
      font-family: $font-01;
      font-size: 1.2rem;
      font-weight: 600;
      margin: 1.5rem 0rem;
    }
    ::v-deep p {
      font-weight: 300;

      img {
        width: 90%;
        max-width: 900px;
      }
    }
  }
</style>